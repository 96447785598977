<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle :text="textTop" />
      <div>
        <h1 class="titels">{{data.title}}</h1>
        <p >{{data.publishDate}}</p>
        <div v-html="data.content"></div>
      </div>
    </div>
    <sidebar />
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
import moment from "moment"
import { homeService } from "@/api"
export default {
  components: {
    textTitle,
    sidebar,
  },
  data() {
    return {
      textTop :"优秀案例",
      ifjournalism:1 ,//1 => 优秀案例 2 =>新闻详情 3 =>学员风采,
      id: this.$route.params.id.split('.')[0], // 截取 .html之前数据
      data: {}
    }
  },
  methods: {
    getDetail() {
      return homeService.getNewsDetail(this.id).then(res => {
        res.publishDate = moment(res.publishDate).format("YYYY-MM-DD")
        this.data = res
      })
    },
    /* getView() {
      let data = {
        tag: this.$route.query.ifjournalism === "2" ? 'news' : '',
        id: this.id
      }
      homeService.getView(data).then(res => {})
    } */
  },
  async created() {
    if (this.$route.params.ifjournalism) {
       this.ifjournalism = this.$route.params.ifjournalism
      console.log(this.ifjournalism);
    }
    if (this.ifjournalism == 1) {
      this.textTop= "优秀案例"
    }else if(this.ifjournalism == 2){
      this.textTop= "新闻资讯"
    }else{
      this.textTop= "学员风采"
    }

    await this.getDetail()

    if (this.data) {
      /* let head = document.getElementsByTagName('head');
      let meta = document.createElement('meta');
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.data.seoKeywords)
      document.querySelector('meta[name="description"]').setAttribute('content', this.data.seoDescription)
      head[0].appendChild(meta)
      document.title = this.data.seoTitle */
    }
   
  },
};
</script>
<style scoped>
.titels {
  padding: 10px 0;
  font-size: 18px;
  text-align: center;
}
.leftBox {
  width: 75%;
}
.leftBox  p {
    text-align: center;
    padding: 10px;
  }
.leftBox >>> img{
  width: 600px;
}
.particularsBox {
  width: 90%;
  margin: 0 5%;
  height: 300px;
  background-color: bisque;
}
</style>